import port from '@config/port';
import name from '@/assets/config/name';
import { navigateTo, delayBack } from './router';

// 挂载到原型
const mounts: any = {
	port,
	name,
	delayBack,
	navigateTo,
	rpx2px: uni.upx2px,
	onCopy(data: string) {
		uni.setClipboardData({ data });
	},
};

const install = (app: any) => {
	for (let key in mounts) app.config.globalProperties['$' + key] = mounts[key];
};

export default {
	install,
	...mounts,
};
