<script setup>
import qs from 'qs';
import port from '@config/port';
import { ref, watch, nextTick } from 'vue';
import request from '@utils/request';
import systemInfo from '@/stores/systemInfo';
import projectData from '@/stores/projectData';
import { SYSTEM_INFO } from '@config/constant';
import { onLaunch, onShow } from '@dcloudio/uni-app';
import { checkUpdated } from '@utils/methods';
// #ifndef H5
//1. 导入uni身份信息管理模块
import uniIdPagesInit from '@/uni_modules/uni-id-pages/init.js';
//2. 导入uniIm
import uniIm from '@/uni_modules/uni-im/sdk/index.js';
// 3.引入扩展插件（项目默认引入了，扩展插件uniImMsgReader用于展示消息是否已读）
import MsgReaderExtension from '@/uni_modules/uni-im-msg-reader/extension.js';
import { mutations as uniIdMutations } from '@/uni_modules/uni-id-pages/common/store.js';
// #endif
// #ifdef APP
import { checkAndroidPushPermission } from '@utils/permission.js';
// #endif

const mid = ref(''),
	sysInfo = systemInfo(),
	prjData = projectData();

sysInfo.$subscribe((mutation, state) =>
	uni.setStorage({
		key: SYSTEM_INFO,
		data: state,
	})
);

const bindMid = debounce(() => {
	if (sysInfo.token && mid.value) {
		request.get({
			resErr: true,
			showLoad: false,
			params: { mid: mid.value },
			port: port.MEMBER_BINDMEMBER,
		});
	}
}, 300);

onLaunch(async ({ query }) => {
	sysInfo.setSysData(query);

	watch(mid, () => bindMid(), { immediate: true });

	await nextTick();
	// #ifndef H5
	//4. 安装uniIm扩展插件
	MsgReaderExtension.install();
	//5. 初始化uni身份信息管理模块
	uniIdPagesInit();
	//6. 初始化uniIm
	uniIm.init();
	// #endif
	// #ifdef APP
	setTimeout(checkAndroidPushPermission, 10000);
	// #endif

	watch(
		() => sysInfo.token,
		(token) => {
			bindMid();
			prjData.fetchConfig('init');
			prjData.fetchUserInfo('init');
			token && prjData.getShareData();

			// #ifndef H5
			if (token) {
				request
					.get(port.UNI_GETTOKEN, { isRefresh: 1, deviceSn: getDeviceId() })
					.then(async ({ token, tokenExpired }) => {
						uni.setStorageSync('uni_id_token_expired', new Date(tokenExpired).getTime());
						uni.setStorageSync('uni_id_token', token);

						// 获取push的ClientId同步到uni-id
						uni.getPushClientId({
							async success(e) {
								// console.log(e)
								let pushClientId = e.cid;
								// console.log(pushClientId);
								let res = await uniIdCo.setPushCid({
									pushClientId,
								});
								console.log('getPushClientId', res);
							},
							fail(e) {
								console.log(e);
							},
						});
						// 更新本地用户信息
						await uniIdMutations.updateUserInfo();
						// 通知其他模块登录成功
						uni.$emit('uni-id-pages-login-success');
					});
			}
			// #endif
		},
		{ immediate: true }
	);
});

onShow(({ query: { mid: _mid, scene } }) => {
	checkUpdated();
	mid.value = _mid;
	if (scene) {
		scene = decodeURIComponent(scene);
		const { mid: _mid2 } = qs.parse(scene);
		_mid2 && (mid.value = _mid2);
	}
});
</script>

<style lang="scss">
@import '@climblee/uv-ui/index.scss';
/* #ifndef APP-NVUE */
@import '@/assets/styles/public.scss';
/* #endif */
</style>
