interface Uni {
	navigateTo: (options?: { url: string }) => void;
	navigateBack: (options?: { delta?: number }) => void;
	switchTab: (options?: { url: string }) => void;
	reLaunch: (options?: { url: string }) => void;
	redirectTo: (options?: { url: string }) => void;
	getEnv: (callback: (env: { nvue?: boolean; plus?: boolean; h5?: boolean }) => void) => void;
	postMessage: (options?: { data: any }) => void;
}

const uni: Uni = (function () {
	'use strict';
	try {
		var e = {};
		Object.defineProperty(e, 'passive', {
			get: function () {
				return true;
			},
		});
		window.addEventListener('test-passive', () => {}, e);
	} catch (e) {}

	var n = Object.prototype.hasOwnProperty;
	function i(e: any, i: any) {
		return n.call(e, i);
	}

	var t: any[] = [];

	function r() {
		return (window as any).__dcloud_weex_postMessage || (window as any).__dcloud_weex_;
	}

	var o = function (e: any, n: any) {
		var i = { options: { timestamp: +new Date() }, name: e, arg: n };
		if (r()) {
			if ('postMessage' === e) {
				var o = { data: [n] };
				return (window as any).__dcloud_weex_postMessage
					? (window as any).__dcloud_weex_postMessage(o)
					: (window as any).__dcloud_weex_.postMessage(JSON.stringify(o));
			}
			var a = { type: 'WEB_INVOKE_APPSERVICE', args: { data: i, webviewIds: t } };
			(window as any).__dcloud_weex_postMessage
				? (window as any).__dcloud_weex_postMessageToService(a)
				: (window as any).__dcloud_weex_.postMessageToService(JSON.stringify(a));
		}
		if (!(window as any).plus)
			return (window as any).parent.postMessage(
				{ type: 'WEB_INVOKE_APPSERVICE', data: i, pageId: '' },
				'*'
			);
		if (0 === t.length) {
			var d = plus.webview.currentWebview();
			if (!d) throw new Error('plus.webview.currentWebview() is undefined');
			var s = d.parent(),
				w = '';
			(w = s ? s.id : d.id), t.push(w);
		}
		if (plus.webview.getWebviewById('__uniapp__service'))
			(plus.webview as any).postMessageToUniNView(
				{ type: 'WEB_INVOKE_APPSERVICE', args: { data: i, webviewIds: t } },
				'__uniapp__service'
			);
		else {
			var u = JSON.stringify(i);
			plus.webview
				.getLaunchWebview()
				.evalJS(
					'UniPlusBridge.subscribeHandler("'
						.concat('WEB_INVOKE_APPSERVICE', '",')
						.concat(u, ',')
						.concat(JSON.stringify(t), ');')
				);
		}
	};

	var a: any = {
		navigateTo: function () {
			var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
			var n = e.url;
			o('navigateTo', { url: encodeURI(n) });
		},
		navigateBack: function () {
			var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
			var n = e.delta;
			o('navigateBack', { delta: parseInt(n) || 1 });
		},
		switchTab: function () {
			var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
			var n = e.url;
			o('switchTab', { url: encodeURI(n) });
		},
		reLaunch: function () {
			var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
			var n = e.url;
			o('reLaunch', { url: encodeURI(n) });
		},
		redirectTo: function () {
			var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
			var n = e.url;
			o('redirectTo', { url: encodeURI(n) });
		},
		getEnv: function (callback: any) {
			r()
				? callback({ nvue: true })
				: (window as any).plus
				? callback({ plus: true })
				: callback({ h5: true });
		},
		postMessage: function () {
			var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
			o('postMessage', e.data || {});
		},
	};

	var d = /uni-app/i.test(navigator.userAgent);
	var s = /Html5Plus/i.test(navigator.userAgent);
	const uni: any = {};
	for (var key in a) {
		if (i(a, key)) {
			uni[key] = a[key];
		}
	}

	if (!uni.getEnv) {
		uni.getEnv = function (callback: any) {
			if (r()) {
				callback({ nvue: true });
			} else if ((window as any).plus) {
				callback({ plus: true });
			} else {
				callback({ h5: true });
			}
		};
	}

	return uni;
})();

export default uni;
