export const API_BASE: any = {
	SIGN_KEY: 'l]Ybbrj_ZnFz4~9VB7)Gq7[0HGCY]oq{1*I]Eji_e[(~qVRYB#axu+r6IDzeE0*EvX2&BCWt~QmXZc==',
	dev: {
		wap: 'https://wap.dev.llmf.uduoduo.vip',
		api: 'https://api.dev.llmf.uduoduo.vip/Public/boosoov2/',
	},
	prod: {
		wap: 'https://wap.llmf.zyzxiang.com',
		api: 'https://api.llmf.zyzxiang.com/Public/boosoov2/',
	},
};
