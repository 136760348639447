import port from '@config/port';
import { defineStore } from 'pinia';
import systemInfo from './systemInfo';
import { USER_INFO } from '@config/constant';
import request, { mergeReq } from '@utils/request';

export default defineStore('projectData', {
	state: (): {
		config: any;
		userInfo: any;
		shareData: any;
		versionData: any;
	} => ({
		config: {},
		userInfo: {},
		shareData: {
			path: '',
			title: '',
			imageUrl: '',
		},
		versionData: {
			isOnline: 0,
		},
	}),

	actions: {
		async getTimestamp() {
			const { timestamp } = await this.fetchConfig('init');
			return AES.encrypt(timestamp);
		},
		getCreditName(type: string) {
			const list = this.config.creditList || [];
			for (let val of list) {
				if (val.creditType == type) {
					return val.creditName;
				}
			}
			return '';
		},
		async getShareData() {
			request
				.get({
					showLoad: false,
					params: { sourceType: 1 },
					port: port.SHARE_GETSHAREDATA,
				})
				.then(({ thumb, title, url }) => {
					this.shareData.path = url;
					this.shareData.title = title;
					this.shareData.imageUrl = thumb;
				});
		},
		async fetchUserInfo(type: string = 'default'): Promise<any> {
			if (Object.keys(this.userInfo).length > 0 && type == 'default') {
				return this.userInfo;
			} else if (systemInfo().token) {
				try {
					let info = await mergeReq({ port: port.MEMBER_GETMEMBERDETAIL });
					this.userInfo = info;
					uni.setStorageSync(USER_INFO, info);
					return info;
				} catch (error) {
					this.userInfo = {};
					return {};
				}
			} else {
				this.userInfo = {};
				return {};
			}
		},

		async fetchConfig(type: string = 'default'): Promise<any> {
			if (Object.keys(this.config).length > 0 && type == 'default') {
				return this.config;
			} else {
				try {
					let config = await mergeReq({ port: port.SYSTEM_GETSETDATA });
					this.config = config;
					return config;
				} catch (error) {
					return this.config;
				}
			}
		},
		async fetchVersionData(): Promise<any> {
			try {
				let info = await mergeReq({ port: port.VERSION_GETVERSION });
				this.versionData = info;
				return this.versionData;
			} catch (error) {
				return this.versionData;
			}
		},
	},
});
