<template>
	<view class="common-bottom-box" :style="{ height: sysInfo.bh + 'px' }"></view>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import systemInfo from '@/stores/systemInfo';

const sysInfo = systemInfo();

onMounted(() => {
	let { bottom = 0 } = uni.getWindowInfo().safeAreaInsets;
	sysInfo.bh = bottom;
});
</script>
